import { _request_KEYCLOAK } from "../../request/_request_KEYCLOAK";

export const sharePreSchecudle = async ({
  Id_Chamado,
  email,
  nome
}) => {
  let options = {
    method: "POST",
    url: `${process.env.REACT_APP_TICKET_INT003}/share`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      email,
      nome,
      Id_Chamado
    },
  };

  return new Promise((resolve, reject) => {
    _request_KEYCLOAK(options)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
