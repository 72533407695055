import React, { createContext, useReducer, useContext, useEffect } from "react";

const initialState = {
  Descricao_Problema: "",
  Endereco: "",
  Integracao: "",
  Razao_Social: "",
  Numero_Serie: "",
  Titulo_Problema: "",
  Contrato: "",
  Celular: "",
  Email: "",
  Nome: "",
  Numero_Filial: "",
  Nome_Equipamento: "",
  // Operacao_nome: "",
  // Operacao_email: "",
  // Operacao_celular: ""
};

const Int010PostTechTicketContext = createContext(initialState);

const int010PostTechTicketReducer = (state, action) => {
  switch (action.type) {
    case "SET_PAYLOAD":
      const payload = state;
      for (const key in payload) {
        if (action.payload[key]) {
          payload[key] = action.payload[key];
        }
        continue;
      }
      return payload;
    case "RESET_STATE":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export const Int010PostTechTicketProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    int010PostTechTicketReducer,
    initialState,
    () => {
      const localData = localStorage.getItem("int010PostTechTicketState");
      return localData ? JSON.parse(localData) : initialState;
    },
  );

  useEffect(() => {
    localStorage.setItem("int010PostTechTicketState", JSON.stringify(state));
    // console.log(state)
  }, [state]);

  const purgeInt010PostTechTicketState = () => {
    localStorage.removeItem("int010PostTechTicketState");
    dispatch({ type: "RESET_STATE" });
  };

  return (
    <Int010PostTechTicketContext.Provider
      value={{ state, dispatch, purgeInt010PostTechTicketState }}
    >
      {children}
    </Int010PostTechTicketContext.Provider>
  );
};

export const useInt010PostTechTicket = () => {
  const context = useContext(Int010PostTechTicketContext);
  if (!context) {
    throw new Error(
      "useInt010PostTechTicket must be used within an Int010PostTechTicketProvider",
    );
  }
  return context;
};
