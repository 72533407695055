import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { PageColumn } from "@layout/Outlet/PageColumn";

function Navbar({ title, isRegister, mb }) {
  const handleArrowBack = () => {
    window.history.back()
  }
  return (
    <Box sx={{width: '100%' }}>
      <AppBar position="static" style={{ marginBottom: mb, zIndex: "1000" }} >
        <PageColumn>
          <Toolbar variant="dense" sx={{paddingLeft: {lg: 0}, paddingRight: {lg: 0}}}>
            {
              isRegister ? 
              (
                <>
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                  >
                    <ArrowBackIcon onClick={handleArrowBack} />
                  </IconButton>
                </>
              ) :
              null
            }
            <Typography variant="h6" color="inherit" component="div">
              {title}
            </Typography>
          </Toolbar>
        </PageColumn>
      </AppBar>
    </Box>
  );
}

export default Navbar;
