import Navbar from "@layout/Outlet/navbar";
import Divider from "@mui/material/Divider";
import { Button, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { sendDeliveryCopy } from "@services/api/index";
import { PageColumn } from "@layout/Outlet/PageColumn";
import { useSnackbar } from "notistack";
import { useDashboardDevolution } from "../../../../../assets/js/reducers/_ticket/_dashboardDevolution";
import { sharePreSchecudle } from "../../../../../_services/api/devolution/share_preschedule";

function CallingSharePreSchedule() {
  const [available, setAvailable] = useState(false);
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const { state: dashboardDevolutionState } = useDashboardDevolution();
  const deliveryCopy = dashboardDevolutionState.notPreSchedule;

  const { enqueueSnackbar } = useSnackbar();

  const handleEmail = e => {
    setEmail(e.target.value);
  };

  const handleFullName = e => {
    setFullName(e.target.value);
  };

  const handleSubmit = () => {

    let payload = {
      no_nfe: deliveryCopy?.Numero_NF_Remessa,
      codigo_filial: deliveryCopy?.Numero_Filial,
      email: email,
      nome: fullName,
    };

    sharePreSchecudle(payload).then(response => {
      console.log(response)
    });
    window.history.back();

    setTimeout(() => {
      enqueueSnackbar(
        "2ª via solicitada. Verifique a caixa de entrada do seu  E-mail.", 
        {
          variant: 'success'
        }
      );
    }, 1000);
  };

  const handleCancel = () => {
    window.history.back();
  };

  useEffect(() => {
    if (fullName !== "" && email !== "") {
      setAvailable(prev => (prev = true));
    }

    return () => {
      setAvailable(prev => (prev = false));
    };
  }, [fullName, email]);

  return (
    <div style={{ width: "100%" }}>
      <Navbar title="Compartilhamento pre-agendamento" isRegister={true} />
      <PageColumn>
        <div className="calling_home">
          <div className="calling_delivery_copy__body">
            <span>
              Informe abaixo para qual E-mail iremos compartilhar a devolução:
            </span>

            <div className="delivery_copy__send">
              <TextField
                fullWidth
                id="outlined-basic"
                label="E-mail"
                variant="outlined"
                helperText="Apenas um endereço permitido."
                onChange={handleEmail}
              />

              <TextField
                fullWidth
                id="outlined-basic"
                label="Nome Completo"
                variant="outlined"
                helperText="Identificação da pessoa que receberá a 2ª via."
                sx={{ marginTop: "16px" }}
                onChange={handleFullName}
              />
            </div>
          </div>
          <Divider component="div" />
          <div className="buttons">
            <Button variant="text" onClick={handleCancel}>
              Cancelar
            </Button>
            <Button
              variant={available ? "contained" : "text"}
              disabled={!available}
              onClick={handleSubmit}
            >
              Solicitar
            </Button>
          </div>
        </div>
      </PageColumn>
    </div>
  );
}

export default CallingSharePreSchedule;
